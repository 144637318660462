import React, { useState, forwardRef } from 'react';
import './AboutUs.css'
// Import in your main file (e.g., index.js)
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Center17 from '../assets/center17.jpg'
import Center18 from '../assets/center18.JPG'

const MeditationHall = forwardRef((props, ref,mobref) => {

    const [selectedImage, setSelectedImage] = useState('');
    const [selectedCaption, setSelectedCaption] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentSource, setCurrentSource] = useState(''); // This will track if we are in overview or gallery

    const DiningImages = [
        Center17, // Replace with actual image imports or paths
        Center18,
        Center17, // Replace with actual image imports or paths
        Center18,
        Center17, // Replace with actual image imports or paths
        Center18,
    ];

    const DiningCaptions = [
        "View of dining hall male side.",
        "Entrance of dining hall from male side.",
        "View of dining hall male side.",
        "Entrance of dining hall from male side.",
        "View of dining hall male side.",
        "Entrance of dining hall from male side.",
    ];    
    
    const openModal = (index, source) => {
        if (source === 'dining') {
            setSelectedImage(DiningImages[index]);
            setSelectedCaption(DiningCaptions[index]);
            setCurrentSource('dining'); // Set the current source to gallery
        }
    }
    const handlePrevImage = () => {
       
        if (currentSource === 'dining') {
            const currentIndex = DiningImages.indexOf(selectedImage);
            const prevIndex = (currentIndex - 1 + DiningImages.length) % DiningImages.length;
            setSelectedImage(DiningImages[prevIndex]);
            setSelectedCaption(DiningCaptions[prevIndex]);
        }
    }
    const handleNextImage = () => {
        if (currentSource === 'dining') {
            const currentIndex = DiningImages.indexOf(selectedImage);
            const nextIndex = (currentIndex + 1) % DiningImages.length;
            setSelectedImage(DiningImages[nextIndex]);
            setSelectedCaption(DiningCaptions[nextIndex]);
        }
    }
   


    return (
        <div ref={ref}>
            <section className="section  desktop-gallery" >
                    <h2 className="section-title">Dining</h2>
                    <p className='body1 all-txts'>
                        Students are allotted seats at the beginning of the course which they are supposed
                        to use for the entire course. Students are supposed to adhere to the dining time-table.
                        They are not allowed to keep food in their rooms except with teachers prior permission
                        in special cases like illness or pregnancy.
                    </p>
                    <div id="carouselExampleIndicator" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicatorsAboutUs" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" data-bs-interval="1500"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicatorsAboutUs" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                        <img
                                            src={Center17}
                                            className="d-block w-100 img-gallery"
                                            alt="Image 1"
                                            loading="lazy"
                                            onClick={() => openModal(0, 'dining')}
                                        />
                                        <p className="image-caption-gallery">View of dining hall male side.</p>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                        <img
                                            src={Center18}
                                            className="d-block w-100 img-gallery"
                                            alt="Image 2"
                                            loading="lazy"
                                            onClick={() => openModal(1, 'dining')}
                                        />
                                        <p className="image-caption-gallery">Entrance of dining hall from male side.</p>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                        <img
                                            src={Center17}
                                            className="d-block w-100 img-gallery"
                                            alt="Image 1"
                                            loading="lazy"
                                            onClick={() => openModal(0, 'dining')}
                                        />
                                        <p className="image-caption-gallery">View of dining hall male side.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                        <img
                                            src={Center18}
                                            className="d-block w-100 img-gallery"
                                            alt="Image 2"
                                            loading="lazy"
                                            onClick={() => openModal(1, 'dining')}
                                        />
                                        <p className="image-caption-gallery">Entrance of dining hall from male side.</p>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                        <img
                                            src={Center17}
                                            className="d-block w-100 img-gallery"
                                            alt="Image 1"
                                            loading="lazy"
                                            onClick={() => openModal(0, 'dining')}
                                        />
                                        <p className="image-caption-gallery">View of dining hall male side.</p>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                        <img
                                            src={Center18}
                                            className="d-block w-100 img-gallery"
                                            alt="Image 2"
                                            loading="lazy"
                                            onClick={() => openModal(1, 'dining')}
                                        />
                                        <p className="image-caption-gallery">Entrance of dining hall from male side.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    {isModalOpen && (
                        <div className="modal-gall">
                            <span className="cross-btn" onClick={() => setIsModalOpen(false)}>&times;</span>
                            <img src={selectedImage} className="selected-gall" alt="Selected" />
                            <p className="image-caption">{selectedCaption}</p>
                            <button className="prev-btn" onClick={handlePrevImage}>‹</button>
                            <button className="next-btn" onClick={handleNextImage}>›</button>
                        </div>
                    )}
                </section>
                {/*mobile view */}
                <section className="section mobile-gallery d-lg-none" >
                    <h2 className="section-title">Dining</h2>
                    <p className='body1 all-txts'>
                        Students are allotted seats at the beginning of the course which they are supposed
                        to use for the entire course. Students are supposed to adhere to the dining time-table.
                        They are not allowed to keep food in their rooms except with teachers prior permission
                        in special cases like illness or pregnancy.
                    </p>
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                        <div class="carousel-indicators-mobile ">
                            <button type="button" data-bs-target="#mobileCarouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#mobileCarouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#mobileCarouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#mobileCarouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#mobileCarouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#mobileCarouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img
                                    src={Center17}
                                    className="d-block w-100 img-mobile-gallery"
                                    alt="Image 1"
                                    loading="lazy"
                                    onClick={() => openModal(0, 'dining')}
                                />
                                <p className="image-caption-gallery">View of dining hall male side.</p>
                            </div>
                            <div className="carousel-item active">
                                <img
                                    src={Center18}
                                    className="d-block w-100 img-mobile-gallery"
                                    alt="Image 2"
                                    loading="lazy"
                                    onClick={() => openModal(1, 'dining')}
                                />
                                <p className="image-caption-gallery">Entrance of dining hall from male side.</p>
                            </div>
                            <div className="carousel-item active">
                                <img
                                    src={Center17}
                                    className="d-block w-100 img-mobile-gallery"
                                    alt="Image 1"
                                    loading="lazy"
                                    onClick={() => openModal(0, 'dining')}
                                />
                                <p className="image-caption-gallery">View of dining hall male side.</p>
                            </div>
                            <div className="carousel-item active">
                                <img
                                    src={Center18}
                                    className="d-block w-100 img-mobile-gallery"
                                    alt="Image 2"
                                    loading="lazy"
                                    onClick={() => openModal(1, 'dining')}
                                />
                                <p className="image-caption-gallery">Entrance of dining hall from male side.</p>
                            </div>
                            <div className="carousel-item active">
                                <img
                                    src={Center17}
                                    className="d-block w-100 img-mobile-gallery"
                                    alt="Image 1"
                                    loading="lazy"
                                    onClick={() => openModal(0, 'dining')}
                                />
                                <p className="image-caption-gallery">View of dining hall male side.</p>
                            </div>
                            <div className="carousel-item active">
                                <img
                                    src={Center18}
                                    className="d-block w-100 img-mobile-gallery"
                                    alt="Image 2"
                                    loading="lazy"
                                    onClick={() => openModal(1, 'dining')}
                                />
                                <p className="image-caption-gallery">Entrance of dining hall from male side.</p>
                            </div>
                        </div>

                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    {isModalOpen && (
                        <div className="modal-gall">
                            <span className="cross-btn" onClick={() => setIsModalOpen(false)}>&times;</span>
                            <img src={selectedImage} className="selected-gall" alt="Selected" />
                            <p className="image-caption">{selectedCaption}</p>
                            <button className="prev-btn" onClick={handlePrevImage}>‹</button>
                            <button className="next-btn" onClick={handleNextImage}>›</button>
                        </div>
                    )}

                </section>

        </div>
    )
});

export default MeditationHall;