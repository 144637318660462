import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaClock } from 'react-icons/fa';
import './ContactUs.css'

const ContactUs = () => {
    return (
        <div className="container custom-container mb-5">
            <div className="row">
                <div className="col-md-4 col-12 d-flex align-items-center justify-content-center mb-3 mb-md-0">
                    <h1>Contacts</h1>
                </div>
                <div className="col-md-8 col-12 map-container">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.8489867594353!2d72.80556931473063!3d19.228203393437583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b158222067c7%3A0x4ce86be376e098ba!2sGlobal%20Vipassana%20Pagoda!5e0!3m2!1sen!2sin!4v1693418371563!5m2!1sen!2sin"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        style={{ border: 0, width: '100%', height: '450px' }}
                        title="Location Map"
                    ></iframe>
                </div>
            </div>
            <h5 className="text-center  mt-5">Dhamma Pattana Vipassana Meditation Center</h5>
            <div className="row justify-content-center mt-5">
                <div className="col-md-4 col-sm-6 mb-4">
                    <div className="card text-center p-4 position-relative">
                        <div className="position-absolute top-0 start-50 translate-middle text-white rounded-circle d-flex align-items-center justify-content-center NumberBox">
                            <span className="number">01</span>
                        </div>
                        <div className="mt-4">
                            <h6>Dhamma Pattana Vipassana Centre:</h6>
                            <p className='body1'><FaMapMarkerAlt className="Icon me-2" />
                                Inside Global Vipassana Pagoda Campus
                                Next to Esselworld, Gorai Village
                                Borivali (West), Mumbai 400091, India
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 mb-4">
                    <div className="card text-center p-4 position-relative">
                        <div className="position-absolute top-0 start-50 translate-middle text-white rounded-circle d-flex align-items-center justify-content-center NumberBox">
                            <span className="number">02</span>
                        </div>
                        <div className="mt-4">
                            <h6>Registration Office:</h6>
                            <p className='body1'><FaPhoneAlt className="Icon me-2" />82918 94650</p>
                            <p className='body1'><FaPhoneAlt className="Icon me-2" />9323805276</p>
                            <p className='body1'><FaEnvelope className="Icon me-2" />registration.pattana@vridhamma.org</p>
                            <p className='body1'><FaClock className="Icon me-2" />(Mon-Sat, 10:00 AM – 5:00 PM only)</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 mb-4">
                    <div className="card text-center p-4 position-relative">
                        <div className="position-absolute top-0 start-50 translate-middle text-white rounded-circle d-flex align-items-center justify-content-center NumberBox">
                            <span className="number">03</span>
                        </div>
                        <div className="mt-4">
                            <h6>During Course:</h6>
                            <p className='body1'><FaPhoneAlt className="Icon me-2" />[91] (22) 50427519 / 82918 94648</p>
                            <p className='body1'><FaPhoneAlt className="Icon me-2" />Female Office - [91] (22) 50427546 / 82918 94649</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
