import React, { useState } from "react";
import './TypeOfCourses.css';

const TypeOfCourses = () => {
    const [activeContent, setActiveContent] = useState("defaultContent");

    const contentData = {
        content1: <p className="body1"><b>Bilingual courses</b> are courses which are taught in two languages.
            All students will hear daily meditation instructions in both languages. The evening discourses will be heard separately.
        </p>,
        content2: <p className="body1"><b>Meditation courses are held at both center and non-center locations.</b> Meditation centers are
            dedicated facilities where courses are held regularly throughout the year. Before meditation centers were established in this
            tradition, all courses were held at temporary sites, such as campgrounds, religious retreat centers, churches, and the like.
            Today, in regions where centers have not yet been established by local students of Vipassana who live in the area, 10-day
            meditation courses are held at non-center course sites.
        </p>,
        content3: <p className="body1"><b>10-day Executive Courses</b> are an introductory course to Vipassana Meditation especially for
            business executives and government officials where the technique is taught step-by-step each day. For more information please
            visit the Executive course website. The courses begin after a 2-4 pm registration period and orientation, followed by 10 full
            days of meditation, and end the morning of the 11th day by 7:30 am.
        </p>,
        content4: <p className="body1"><b>Special 10-day Courses</b> are open only to serious Old Students committed to this technique who
            have completed a minimum of five 10-day courses, one Satipatthana Sutta course, given Dhamma Service at least one 10-day course,
            and have been practicing regularly for at least two years.<br /><br />
            Applications must be submitted well in advance as processing may take some time. Students who speak neither English nor one of
            the other announced course languages may apply for the course, however, acceptance into the course will depend on the availability
            of required course materials, suitable translators, and the permission of the teacher conducting the course.
        </p>,
        content5: <p className="body1"><b>20-day Courses</b> are open only to serious Old Students committed to this technique who have
            completed a minimum of five 10-day courses, one Satipatthana Sutta course, given Dhamma Service in at least one 10-day course,
            and have been practicing regularly for at least two years.<br /><br />
            Applications must be submitted well in advance as processing may take some time. Students who speak neither English nor one of
            the other announced course languages may apply for the course, however acceptance into the course will depend on the availability
            of required course materials, suitable translators, and the permission of the teacher conducting the course.
        </p>,
        content6: <p className="body1"><b>Satipatthana Sutta Courses</b> have the same timetable and discipline as 10-day courses. The
            difference is that in the taped evening discourses the Satipatthana Sutta is carefully examined. This is the principal text in
            which the technique of Vipassana is systematically explained. These courses are open to serious old students who have
            sat (not including courses served) at least three 10-day courses, have not been practising any other meditation techniques
            since the last 10-day course, have been practising this technique of Vipassana for at least one year, and who are trying to
            maintain their meditation practice and the five precepts in their daily lives, at the very minimum from the time of
            applying to the course.
        </p>,
        content7: <p className="body1"><b>Old Student Short Courses</b> (1-3 days) are for any student who has completed the 10-day
            course with S.N. Goenka and his assistant teachers. All old students are welcome to apply to attend these courses, including
            those where it has been some time since their last course.
        </p>,
        defaultContent: <p className="body1"><b>All courses are run solely on a donation basis. All expenses are met by donations from those who,
            having completed a course and experienced the benefits of Vipassana, wish to give others the same opportunity.
            Neither the Teacher nor the assistant teachers receive remuneration; they and those who serve the courses volunteer
            their time. Thus, Vipassana is offered free from commercialization.</b><br /><br />
            <b>Old students are those who have completed a 10-day Vipassana Meditation course with S.N. Goenka or his Assistant
                Teachers. Old students have the opportunity to provide Dhamma Service at the courses listed.</b>
        </p>
    };

    const getButtonStyle = (buttonId) => ({
        backgroundColor: activeContent === buttonId ? "#3590db" : "white",
        color: activeContent === buttonId ? "white" : "#000",
        borderColor: activeContent === buttonId ? "#3590db" : "#ced4da",
        cursor: "pointer",
        transition: "background-color 0.3s ease, color 0.3s ease",
        width: "100%",
        textAlign: "left",
        padding: "10px 10px",
        borderRadius: "5px",
        marginBottom: "10px",
    });

    return (
        <div className="container type-of-courses-container custom-container mb-5  vedio-text">
            <div className="body1 list-group">
                <button
                    style={getButtonStyle("content1")}
                    onClick={() => setActiveContent("content1")}
                >
                    Bilingual courses
                </button>
                <button
                    style={getButtonStyle("content2")}
                    onClick={() => setActiveContent("content2")}
                >
                    Meditation courses
                </button>
                <button
                    style={getButtonStyle("content3")}
                    onClick={() => setActiveContent("content3")}
                >
                    10-day Executive Courses
                </button>
                <button
                    style={getButtonStyle("content4")}
                    onClick={() => setActiveContent("content4")}
                >
                    Special 10-day Courses
                </button>
                <button
                    style={getButtonStyle("content5")}
                    onClick={() => setActiveContent("content5")}
                >
                    20-day Courses
                </button>
                <button
                    style={getButtonStyle("content6")}
                    onClick={() => setActiveContent("content6")}
                >
                    Satipatthana Sutta Courses
                </button>
                <button
                    style={getButtonStyle("content7")}
                    onClick={() => setActiveContent("content7")}
                >
                    Old Student Short Courses
                </button>
            </div>

            <div className="main content-section p-3">
                <h5 className='mb-4'>Type of Courses</h5>
                <p className='body1'>
                    {contentData[activeContent]}
                </p>
            </div>
        </div>
    );
};

export default TypeOfCourses;