const DuringTheCourse = () => {
    return (
        <div className="container custom-container mb-5">
            <h5 className="text-center mb-4">During the Course</h5>
            <div className="row">
                <div className="col-12">
                    <h5>Food</h5>
                    <p className="body1">
                        A simple vegetarian menu, developed to satisfy the needs of most students, is offered at the center. Please note that, no outside food is allowed at the center.
                    </p>
                    
                   <h6>Breakfast includes the following :</h6>
                    <ul>
                        <li className="body1">It is not a rite or ritual based on blind faith.</li>
                        <li className="body1">It is neither an intellectual nor a philosophical entertainment.</li>
                        <li className="body1">It is not a rest cure, a holiday, or an opportunity for socializing.</li>
                        <li className="body1">It is not an escape from the trials and tribulations of everyday life.</li>
                    </ul>

                    <p className="body1">For lunch, we provide various simple cooked vegetarian meals. In the evening we provide fruits (apples, oranges, bananas) in addition to tea/milk, lemon water for old students.</p>
                    <p className="body1">If you have any special food requirement due to medically diagnosed condition, please inform us in advance.</p>

                    <h6>Accommodation</h6>
                    <p className="body1">Men and women have segregated accommodations with single occupancy rooms. All rooms are self contained.</p>
                    <p className="body1">We have only a limited number of single rooms. They cannot be reserved and are assigned on the basis of medical or other needs, on the day that the course begins. Routine requests for single occupancy will be accommodated, after such needs are taken care of.</p>
                    <p className="body1">We request students to accept the accommodation that is available. Please note that rooms are assigned independently of arrival time and early arrival for single room is not recommended. </p>
                    <p className="body1">If your request for a single room is related to a medical condition or other concerns such as insomnia or restlessness etc., please inform us in advance.</p>
                    
                    <h6>Course language</h6>
                    <p className="body1">The primary language of instruction at Dhamma Pattana is Hindi & English. If you are unable to understand Hindi or English, please inform us at the time of registration and let us know your language preference. Instructions are available in a limited number of other languages. The center will make every effort to accommodate your request.</p>

                    <h6>Laundry Service</h6>
                    <p className="body1">Laundry service will be available at a nominal cost during the course.</p>
                </div>
            </div>
        </div>
    )
}
export default DuringTheCourse;