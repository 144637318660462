import React from 'react';
import './Donation.css';

function Donation() {
  return (
    <div className="container custom-container mb-5">
        <h5 className=' mb-4 text-center'>Donation </h5>
        <h5 className='mt-4 mb-4'>Course Finances</h5>
        <p className='body1'>
            According to the tradition of pure Vipassana, courses are run solely on a donation basis from old students. In this way courses are supported by those who have realized for themselves the benefits of the practice. Wishing to share these benefits with others, one gives a donation according to one's means and volition. Such donations are the only source of funding for course in this tradition around the world. There is no wealthy foundation or individual sponsoring them. Neither the teachers nor the organizers receive any kind of payment for their service. Thus, the spread of Vipassana is carried out with purity of purpose, free from any commercialism.
        </p>
        <h5 className='mt-4 mb-4'>Who can donate</h5>
        <p className='body1'>
           Donations are accepted only from old students i.e. from students who have completed at least one ten-day course with S.N. Goenka or one of his assisting teachers. Someone taking the course for the first time may give a donation on the last day of the course or any time thereafter.
        </p> 

         <div className="donation-details mt-5 p-4 shadow mx-auto">
        <h5 className='text-center mb-3'>Donation Details</h5>
        <ul className="list-unstyled text-center">
          <li className='body1 mt-2'><strong>Name : </strong> DHAMMA PATTANA VIPASSANA TRUST</li>
          <li className='body1 mt-2' ><strong>Bank : </strong> BOI (VILE PARLE WEST)</li>
          <li className='body1 mt-2'><strong>A/C. No : </strong> 005810110004032</li>
          <li className='body1 mt-2' ><strong>IFSC Code : </strong> BKID0000058</li>
          <li className='body1 mt-2' ><strong>Branch : </strong> VILE PARLE (W)</li>
          <li className='body1 mt-2' ><strong>Contact No. : </strong> 9920052156</li>
          <li className='body1 mt-2' ><strong>Email ID : </strong> 
             <a href="mailto:audits@globalpagoda.org"> audits@globalpagoda.org </a> 
            <br /> <a href="mailto:accounts@globalpagoda.org"> accounts@globalpagoda.org</a> 
          </li>
        </ul>
      </div>  
    </div>
         
  
  )
}

export default Donation;
