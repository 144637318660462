import React, {forwardRef} from 'react';
import './AboutUs.css'
// Import in your main file (e.g., index.js)
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Maps = forwardRef((props, ref) => {

    return (
        <div ref={ref}>
            <section className="section" >
                    <h2 className="section-title">Directions</h2>
                    <p><b>1. From Airport Road Metro Station</b></p>
                    <p className='trans-text'>I. Board Metro Line 1 at Airport Road and head towards DN Nagar.</p>
                    <p className='trans-text'>II. Change to Metro Line 2A (Yellow Line) at DN Nagar and travel towards Borivali West Metro Station.</p>
                    <p className='trans-text'>III. Once at Borivali West, take an auto-rickshaw to Gorai Creek (about 2 km).</p>
                    <p className='trans-text'>IV. From Gorai Creek, take a ferry to either Gorai Village Jetty (₹15) or Esselworld Jetty (₹100 for a round trip). After that, follow directions to the Pagoda (500 m from Esselworld Jetty, or take an auto from Gorai Village Jetty).</p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m24!1m8!1m3!1d117764.41312489842!2d72.8241442!3d19.1750474!3m2!1i1024!2i768!4f13.1!4m13!3e3!4m5!1s0x3be7c9a591e52f03%3A0xa524b2f5a84bb093!2sAirport%20Road%20Metro%20Station%2C%20Metro%20Station%20Airport%20Road%2C%20Metro%20Station%2C%20Vijay%20Nagar%20Colony%20West%2C%20Marol%2C%20Andheri%20East%2C%20Mumbai%2C%20Maharashtra!3m2!1d19.1101437!2d72.8742329!4m5!1s0x3be7b3e06cdab6fb%3A0xfdbce001ca3ed285!2sGlobal%20Pagoda%20Road%2C%20Essel%20world%20Amusement%20Park%2C%20Gorai%2C%20Mumbai%2C%20Maharashtra%20400092!3m2!1d19.2322406!2d72.80458709999999!5e1!3m2!1sen!2sin!4v1728991556161!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        className="map-container "
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>


                    <p className='mt-4'><b> 2. From Chhatrapati Shivaji International Airport</b></p>
                    <p className='trans-text'>I. Take a taxi/auto to Western Express Highway Metro Station and board Metro Line 7 (Red Line) towards Dahisar.</p>
                    <p className='trans-text'>II. At Borivali West Metro Station, follow the same steps as above: take an auto to Gorai Creek, and continue via ferry.</p>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d117747.78764518579!2d72.74920603348383!3d19.198294248529532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x3be7c9d245555ea7%3A0x5bc9191edba06da9!2sChhatrapati%20Shivaji%20Maharaj%20International%20Airport%20Mumbai%20(BOM)%2C%20T2%2C%20Navpada%2C%20Vile%20Parle%20East%2C%20Vile%20Parle%2C%20Mumbai%2C%20Maharashtra!3m2!1d19.0975711!2d72.8745209!4m5!1s0x3be7b3e06cdab6fb%3A0xfdbce001ca3ed285!2sGlobal%20Pagoda%20Road%2C%20Essel%20world%20Amusement%20Park%2C%20Gorai%2C%20Mumbai%2C%20Maharashtra%20400092!3m2!1d19.2322406!2d72.80458709999999!5e1!3m2!1sen!2sin!4v1728991700071!5m2!1sen!2sin"
                        width="600" height="450"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        className="map-container "
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>

                    <p className='mt-4'><b> 3. By Road from Mumbai or Thane</b></p>
                    <p className='trans-text'>I. Use the Western Express Highway, go north towards Dahisar, and turn left at the Mira-Bhayandar crossing (Shivaji Maharaj statue).</p>
                    <p className='trans-text'>II. Follow signs to Esselworld or Global Vipassana Pagoda.</p>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m24!1m8!1m3!1d117704.98133093462!2d72.8803323!3d19.2580246!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x3be7b3e06cdab6fb%3A0xfdbce001ca3ed285!2sGlobal%20Pagoda%20Road%2C%20Essel%20world%20Amusement%20Park%2C%20Gorai%2C%20Mumbai%2C%20Maharashtra%20400092!3m2!1d19.2322406!2d72.80458709999999!4m5!1s0x3be7b8fcfe76fd59%3A0xcf367d85f7c50283!2sThane%2C%20Maharashtra!3m2!1d19.2183307!2d72.9780897!5e1!3m2!1sen!2sin!4v1728991779228!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        className="map-container "
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>

                    <p className='mt-4'><b> 4.By Public Transport </b></p>
                    <p className='trans-text'>I. From Borivali Station (on the Mumbai Suburban Line), take a bus (No. 294 or 247) or an auto to Gorai Creek and proceed with the ferry.</p>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d58848.73558350032!2d72.78065975855102!3d19.26848563566488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e3!4m5!1s0x3be7b0d6db21ad03%3A0xa31af1578987d699!2sBorivali%20Station%20West%2C%20Sundar%20Nagar%2C%20Borivali%20West%2C%20Mumbai%2C%20Maharashtra!3m2!1d19.228757899999998!2d72.85655799999999!4m5!1s0x3be7b3e06cdab6fb%3A0xfdbce001ca3ed285!2sGlobal%20Pagoda%20Road%2C%20Essel%20world%20Amusement%20Park%2C%20Gorai%2C%20Mumbai%2C%20Maharashtra%20400092!3m2!1d19.2322406!2d72.80458709999999!5e1!3m2!1sen!2sin!4v1728991829256!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        className="map-container "
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                    <p className='trans-text'>II. Alternatively, from Bhayandar Station, you can take MBMT Bus No. 4 directly to the Pagoda.</p>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d58850.02333601711!2d72.7744401585275!3d19.264898786390262!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e3!4m5!1s0x3be7b10060dce8bd%3A0x8595a04179fdaba0!2sBhayandar%20Railway%20Station%20West%2C%20Bhayandar%2C%20Chandulal%20Park%2C%20Bhayandar%20West%2C%20Mira%20Bhayandar%2C%20Maharashtra!3m2!1d19.300796899999998!2d72.8467103!4m5!1s0x3be7b3e06cdab6fb%3A0xfdbce001ca3ed285!2sGlobal%20Pagoda%20Road%2C%20Essel%20world%20Amusement%20Park%2C%20Gorai%2C%20Mumbai%2C%20Maharashtra%20400092!3m2!1d19.2322406!2d72.80458709999999!5e1!3m2!1sen!2sin!4v1728991880072!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        className="map-container "
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>

                    <p className='mt-4'><b> 5. Ola or Uber</b></p>
                    <p className='trans-text'>If you’re planning to take an Ola or Uber from Chhatrapati Shivaji Maharaj International Airport to the Global Vipassana Pagoda, here’s what you need to know:</p>
                    <p className='trans-text'>I. Distance : The Pagoda is approximately 40-45 km from the airport.</p>
                    <p className='trans-text'>II. Estimated Travel Time : Depending on traffic, the journey typically takes 1 to 1.5 hours.</p>
                    <p className='trans-text'>III. Fare Estimate : For Ola/Uber services, the cost can range from ₹700 to ₹1,200 depending on the type of vehicle (Mini, Prime, or SUV) and surge pricing during peak hours.</p>
                    <p className='trans-text'>I. Directions : From the airport, you’ll take the Western Express Highway north, cross the Dahisar Toll Naka, and then head towards the Mira-Bhayandar route to reach the Gorai Village area, where the Pagoda is located.</p>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d117747.78764518579!2d72.74920603348383!3d19.198294248529532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x3be7c9d245555ea7%3A0x5bc9191edba06da9!2sChhatrapati%20Shivaji%20Maharaj%20International%20Airport%20Mumbai%20(BOM)%2C%20T2%2C%20Navpada%2C%20Vile%20Parle%20East%2C%20Vile%20Parle%2C%20Mumbai%2C%20Maharashtra!3m2!1d19.0975711!2d72.8745209!4m5!1s0x3be7b3e06cdab6fb%3A0xfdbce001ca3ed285!2sGlobal%20Pagoda%20Road%2C%20Essel%20world%20Amusement%20Park%2C%20Gorai%2C%20Mumbai%2C%20Maharashtra%20400092!3m2!1d19.2322406!2d72.80458709999999!5e1!3m2!1sen!2sin!4v1728991931545!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        className="map-container "
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </section>

        </div>
    )
})

export default Maps;