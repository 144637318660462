const classNames = (...classes) => {
    return classes
      .flatMap((cls) => {
        if (typeof cls === 'string') {
          return cls;
        } else if (typeof cls === 'object' && cls !== null) {
          return Object.entries(cls)
            .filter(([_, value]) => Boolean(value))
            .map(([key]) => key);
        }
        return [];
      })
      .join(' ')
      .trim();
  }
  
  export default classNames;
  