import React from 'react';

const Timetable = () => {
    return (
        <div className="container custom-container mb-5">
            <div className="text-center">
                <h5 className='mb-3'>THE COURSE TIME TABLE</h5>
                <p className="body1">The following timetable for the course has been designed to maintain the continuity of practice. For best results, students are advised to follow it as closely as possible.</p>
            </div>
            <div className="table-responsive">
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <td className="body1">4:00 am</td>
                            <td className="body1">Morning wake-up bell</td>
                        </tr>
                        <tr>
                            <td className="body1">4:30-6:30 am</td>
                            <td className="body1">Meditate in the hall or in your room</td>
                        </tr>
                        <tr>
                            <td className="body1">6:30-8:00 am</td>
                            <td className="body1">Breakfast break</td>
                        </tr>
                        <tr>
                            <td className="body1">8:00-9:00 am</td>
                            <td className="body1">Group meditation in the hall</td>
                        </tr>
                        <tr>
                            <td className="body1">9:00-11:00 am</td>
                            <td className="body1">Meditate in the hall or in your room according to the teacher's instructions</td>
                        </tr>
                        <tr>
                            <td className="body1">11:00-12:00 noon</td>
                            <td className="body1">Lunch break</td>
                        </tr>
                        <tr>
                            <td className="body1">12noon-1:00 pm</td>
                            <td className="body1">Rest and interviews with the teacher</td>
                        </tr>
                        <tr>
                            <td className="body1">1:00-2:30 pm</td>
                            <td className="body1">Meditate in the hall or in your room</td>
                        </tr>
                        <tr>
                            <td className="body1">2:30-3:30 pm</td>
                            <td className="body1">Group meditation in the hall</td>
                        </tr>
                        <tr>
                            <td className="body1">3:30-5:00 pm</td>
                            <td className="body1">Meditate in the hall or in your room according to the teacher's instructions</td>
                        </tr>
                        <tr>
                            <td className="body1">5:00-6:00 pm</td>
                            <td className="body1">Tea break</td>
                        </tr>
                        <tr>
                            <td className="body1">6:00-7:00 pm</td>
                            <td className="body1">Group meditation in the hall</td>
                        </tr>
                        <tr>
                            <td className="body1">7:00-8:15 pm</td>
                            <td className="body1">Discourse in the hall</td>
                        </tr>
                        <tr>
                            <td className="body1">8:15-9:00 pm</td>
                            <td className="body1">Group meditation in the hall</td>
                        </tr>
                        <tr>
                            <td className="body1">9:00-9:30 pm</td>
                            <td className="body1">Question time in the hall</td>
                        </tr>
                        <tr>
                            <td className="body1">9:30 pm</td>
                            <td className="body1">Retire to your own room--Lights out</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Timetable;
