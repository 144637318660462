import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Header from './components/Header/Header';
import ArtOfLiving from './Pages/Vipassana/ArtOfLiving/ArtOfLiving';
import RelatedWebsites from './Pages/Vipassana/RelatedWebsites/RelatedWebsites';
import QuestionsAnswers from './Pages/Vipassana/QuestionsAnswers/QuestionsAnswers';
import IntroductoryVideos from "./Pages/Vipassana/IntroductoryVideos/IntroductoryVideos";
import WhatIsVipassana from "./Pages/Vipassana/WhatIsVipassana/WhatIsVipassana";
import TheCodeOfDiscipline from "./Pages/Vipassana/TheCodeOfDiscipline/TheCodeOfDiscipline";
import GoenkaPage from "./Pages/Vipassana/GoenkaPage/GoenkaPage";
import ArrivalAndDeparture from "./Pages/Courses/ArrivalAndDeparture/ArrivalAndDeparture";
import TypeOfCourses from "./Pages/Courses/TypeOfCourses/TypeOfCourses";
import DuringTheCourse from "./Pages/Courses/DuringTheCourse/DuringTheCourse";
import WhatToBring from "./Pages/Courses/WhatToBring/WhatToBring";
import AboutUs from './Pages/TheCentre/AboutUs/AboutUs';
import HowToApply from './Pages/Courses/HowToApply/HowToApply';
import TimeTable from './Pages/Courses/TimeTable/TimeTable';
import ContactUs from './Pages/TheCentre/ContactUs/ContactUs';
import Login from './Pages/Login/Login';
import Home from './Pages/Home/Home';
import Footer from './components/Footer/Footer';
import Services from './Pages/Services/Services';
import Donation from './Pages/Donation/Donation';
import PageNotFound from './Pages/PageNotFound/PageNotFound';
import ServicesLogin from './Pages/Login/ServicesLogin';


function App() {
  return (
    <Router>
      <ScrollToTop />
      <div >
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/art-of-living" element={<ArtOfLiving />} />
          <Route path="/related-websites" element ={<RelatedWebsites/>} />
          <Route path="/the-code-of-discipline" element={<TheCodeOfDiscipline/>} />
          <Route path="/what-is-vipassana" element={<WhatIsVipassana/>} />
          <Route path="/questions-answers" element={<QuestionsAnswers/>} />
          <Route path="/introductory-videos" element={<IntroductoryVideos/>} />
          <Route path="/goenka-page" element={<GoenkaPage/>} />
          <Route path="/arrival-and-departure" element={<ArrivalAndDeparture/>} />
          <Route path="/how-to-apply" element={<HowToApply />} />
          <Route path="/type-of-courses" element={<TypeOfCourses />} />
          <Route path="/during-the-course" element={<DuringTheCourse/>} />
          <Route path="/what-to-bring" element={<WhatToBring/>} />
          <Route path="/about-us" element={<AboutUs/>} />
          <Route path="/contact-us" element={<ContactUs/>} />
          {/* <Route path="/how-to-reach" element={<HowToReach/>} /> */}
          <Route path="/time-table" element={<TimeTable />} />
          {/* <Route path="/the-centre" element={<TheCentre />} /> */}
          <Route path="/login" element={<Login />} />
          {/* <Route path="/center-photos" element={<CenterPhotos/>} /> */}
          <Route path="/services" element={<Services/>} />
          <Route path="/donation" element={<Donation/>} />
          <Route path="/services-login" element={<ServicesLogin/>} />
          <Route path="*" element={<PageNotFound />}/>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}
export default App;

