import React from 'react';
import './HowToApply.css';

const HowToApply = () => {

  const steps = [
    {
      title: 'Read the Code of Discipline ',
      description: 'Click here',
      link: '/the-code-of-discipline'
    },
    {
      title: 'View the Course List',
      description: 'Click here',
      link: 'https://www.dhamma.org/en/schedules/schpattana'
    },
    {
      description: 'Click on “Apply” next to the desired course date',
    },
    {
      title: 'Await notification:',
      description: ' All correspondence will be through e-mail. If you give an e-mail address in your application, You will receive notification for confirmation up to one month before start of the course.',
    },
  ];

  return (
    <div className="container mb-5 custom-container">

      <h5 className="text-center mb-5">How to apply for a course</h5>
      <p className="text-center subtitle1 mb-3">An online application is required to register for all courses.</p>
      <h6 className="text-center mb-3">Steps:</h6>

      <div className="row justify-content-center mb-4">

        {steps.map((step, index) => (
          <div key={index} className="col-12">
            <div className="step-box">
              <div className="step-content">
                <h3>{step.title}</h3>
                {step.link ? (
                  <a href={step.link} className="step-link" target="_self" rel="noopener noreferrer">
                    <p>{step.description}</p>
                  </a>
                ) : (
                  <p>{step.description}</p>
                )}
              </div>
              {index < steps.length - 1 && (
                <div className="step-arrow "></div>
              )}
            </div>
          </div>
        ))}
        
      </div>

    </div>
  );
};

export default HowToApply;

