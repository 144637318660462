import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Footer.css";
import ImageModal from "../ImageModal/ImageModal";

import FooterImg1 from "../../Pages/TheCentre/CenterPhotos/assets/3.jpg";
import FooterImg2 from "../../assets/footerpic2.jpg";
import FooterImg3 from "../../Pages/TheCentre/CenterPhotos/assets/6.jpg";
import FooterImg4 from "../../Pages/TheCentre/CenterPhotos/assets/7.jpg";
import FooterImg5 from "../../assets/IMG_0538.jpg";
import FooterImg6 from "../../Pages/TheCentre/CenterPhotos/assets/img4.jpg";
import FooterImg7 from "../../Pages/TheCentre/CenterPhotos/assets/8.jpg";
import FooterImg8 from "../../Pages/TheCentre/CenterPhotos/assets/img7.jpg";
import FooterImg9 from "../../Pages/TheCentre/CenterPhotos/assets/img8.jpg";
import Center1 from "../../Pages/TheCentre/assets/center1.jpg";
import Center2 from "../../Pages/TheCentre/assets/center2.JPG";
import Center3 from "../../Pages/TheCentre/assets/center3.jpg";
import Center4 from "../../Pages/TheCentre/assets/center4.JPG";
import Center5 from "../../Pages/TheCentre/assets/center5.JPG";
import Center6 from "../../Pages/TheCentre/assets/center6.JPG";
import Center7 from "../../Pages/TheCentre/assets/center7.JPG";
import Center8 from "../../Pages/TheCentre/assets/center8.JPG";
import Center9 from "../../Pages/TheCentre/assets/center9.JPG";
import Center10 from "../../Pages/TheCentre/assets/center10.JPG";
import Center11 from "../../Pages/TheCentre/assets/center11.JPG";
import Center13 from "../../Pages/TheCentre/assets/center13.JPG";
import Center14 from "../../Pages/TheCentre/assets/center14.JPG";
import Center15 from "../../Pages/TheCentre/assets/center15.JPG";
import Center16 from "../../Pages/TheCentre/assets/center16.JPG";
import Center17 from "../../Pages/TheCentre/assets/center17.jpg";
import Center18 from "../../Pages/TheCentre/assets/center18.JPG";
import Gallery5 from "../../Pages/TheCentre/assets/center17.jpg";

const Footer = () => {
  const [showMap, setShowMap] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const navigate = useNavigate();
  const mediaBoxRef = useRef(null);
  const [showText, setShowText] = useState(true);
  const [showErrorIcon, setShowErrorIcon] = useState(false);
  const [hideArrow, setHideArrow] = useState(true);
  const scrollArrowRef = useRef(null);
  const galleryImages = [
    FooterImg1,
    FooterImg2,
    FooterImg3,
    FooterImg4,
    FooterImg5,
    FooterImg6,
    FooterImg7,
    FooterImg8,
    FooterImg9,
    Center1,
    Center2,
    Center3,
    Center4,
    Center5,
    Center6,
    Center7,
    Center8,
    Center9,
    Center10,
    Center11,
    Center13,
    Center14,
    Center15,
    Center16,
    Center17,
    Center18,
    Gallery5,
  ];

  const handleMapClick = () => {
    setShowMap(true);
  };

  const handleCloseMap = () => {
    setShowMap(false);
  };

  const handleImageClick = (index) => {
    setSelectedIndex(index);
  };

  const handleCloseImageModal = () => {
    setSelectedIndex(null);
  };

  const handlePrevClick = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex === 0 ? galleryImages.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex === galleryImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleScroll = () => {
    const isMoreThan300FromTop = window.scrollY > 300;
    setShowErrorIcon(isMoreThan300FromTop);

    const isNearTop = window.scrollY < 100;
    setHideArrow(isNearTop);

    const isNearBottom =
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight - 150;
    setHideArrow((prev) => prev || isNearBottom);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(false);
    }, 10000);

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <footer className="bg-color1 pt-5 pb-2 position-relative w-100">
      <div className="container">
        <div className="footer-data position-relative w-100">
          {showErrorIcon && !hideArrow && (
            <div
              ref={scrollArrowRef}
              className="scroll-to-top"
              onClick={scrollToTop}
            >
              <svg
                viewBox="0 0 16 28"
                fill="none"
                className="arrow"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 0L14.9282 7.5H1.0718L8 0Z" fill="#1E1E20"></path>
                <path d="M8 7L8 28" stroke="#1E1E20"></path>
              </svg>
            </div>
          )}
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="widget-box w-100">
                <p className="widget-title">Dhamma Pattana Vipassana Centre</p>
                <p className="subtitle1 mb-0 lineheight">
                  Inside Global Vipassana Pagoda Campus Next to Esselworld,
                  Gorai Village Borivali (West), Mumbai 400091, India
                </p>
                <div className="scl-links d-flex flex-wrap w-100 mt-3">
                  <a
                    href="https://www.facebook.com/dhammapattana"
                    title="Facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="material-icons">facebook</span>
                  </a>
                  <a
                    href="https://www.instagram.com/dhammapattana"
                    title="Instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="fa fa-instagram mt-2 icons"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="widget-box w-100">
                <p className="widget-title">Registration Office:</p>
                <ul className="cont-list mb-0 list-unstyled w-100">
                  <li className="subtitle1">
                    <a
                      href="javascript:void(0);"
                      onClick={handleMapClick}
                      className="subtitle1"
                      title="Open Map"
                    >
                      <span className="material-icons">location_on</span>
                      View on Google Maps
                    </a>
                  </li>
                  <li className="subtitle1">
                    <span className="material-icons">phone</span>
                    82918 94650 / 9323805276
                  </li>
                  <li className="subtitle1">
                    <span className="material-icons">email</span>
                    <a
                      className="link"
                      href="mailto:registration.pattana@vridhamma.org"
                      title=""
                    >
                      registration.pattana@vridhamma.org
                    </a>
                  </li>
                  <li className="subtitle1">
                    (Mon-Sat, 10:00 AM – 5:00 PM only)
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="widget-box w-100">
                <p className="widget-title">Our Gallery</p>
                <div className="widget-gallery w-100">
                  <div className="row g-2">
                    {galleryImages.slice(0, 9).map((imgSrc, index) => (
                      <div className="col-4" key={index}>
                        <div
                          className="d-block overflow-hidden position-relative w-100"
                          onClick={() => {
                            console.log("test")
                            handleImageClick(index)
                          }}
                        >
                          <img
                            className="img-fluid small-image"
                            src={imgSrc}
                            alt={`Widget Gallery Image ${index + 1}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-2 border-top">
        <p className="mt-4 text-white body2">
          Copyright © 2024, Dhamma Pattana
        </p>
      </div>

      {showMap && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Dhamma Pattana Vipassana Centre Map
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseMap}
                ></button>
              </div>
              <div className="modal-body">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.265299400135!2d72.80352522602463!3d19.227266197204784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c4e0ff4f69a9%3A0xc03c848c22ed8c87!2sDhamma%20Pattana%20Vipassana%20Centre!5e0!3m2!1sen!2sin!4v1698179924106!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  title="Google Map"
                ></iframe>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseMap}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedIndex !== null && (
        <ImageModal
          imageSrc={galleryImages[selectedIndex]}
          alt={`Center ${selectedIndex + 1}`}
          handlePrevClick={handlePrevClick}
          handleNextClick={handleNextClick}
          handleClose={handleCloseImageModal}
        />
      )}
    </footer>
  );
};

export default Footer;
