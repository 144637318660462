import React, { useState, useEffect } from 'react';
import "./Header.css";
import { useLocation, useNavigate } from 'react-router-dom';
import Logo1 from "../../../src/assets/logowhite.png";
import Logo2 from "../../../src/assets/logoblack.png";

const Header = () => {
  const location = useLocation(); 
  const isHomePage = location.pathname === "/"; 

  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992); 
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(true); 

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    const videoElement = document.querySelector('video');
    if (videoElement) {
      const handleVideoPlay = () => setIsVideoPlaying(true);
      const handleVideoPause = () => setIsVideoPlaying(false);

      videoElement.addEventListener('play', handleVideoPlay);
      videoElement.addEventListener('pause', handleVideoPause);

      return () => {
        videoElement.removeEventListener('play', handleVideoPlay);
        videoElement.removeEventListener('pause', handleVideoPause);
      };
    }

    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  //logged in in home page
  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [location]);
  const handleToggle = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  const closeOffcanvas = () => {
    setIsOffcanvasOpen(false);
  };

  const handleDropdownClick = (dropdownId) => {
    setActiveDropdown(dropdownId);
  };

  const handleLogout = () => {
    const isConfirmed = window.confirm("Are you sure you want to logout?");
    if (isConfirmed) {
      localStorage.removeItem('isAuthenticated');
      setIsAuthenticated(false);
      closeOffcanvas();
      navigate("/login");
    }
  };
  const logoStyle = {
    marginLeft: !isMobile ? (isAuthenticated ? '150px' : '200px') : '0px',
    marginRight: !isMobile ? '0px' : 'auto',
  };
  
  const navbarStyle = {
    marginRight: !isMobile ? (isAuthenticated ? '150px' : '200px') : '0px',
  };
  

  const desktopTextColorClass = isHomePage && !isScrolled ? 'text-white' : 'text-black';
  const textColorClass = isMobile ? 'text-black' : desktopTextColorClass;
  const logoSrc = !isHomePage ? Logo2 : (isVideoPlaying && !isScrolled ? Logo1 : Logo2);
  const togglerIconClass = isHomePage && !isScrolled? 'navbar-toggler-icon-white': 'navbar-toggler-icon-black';

  return (
    <div className={`Header ${isOffcanvasOpen ? 'offcanvas-open' : ''} ${isScrolled ? 'scrolled' : ''}`}>
      <nav className="navbar navbar-expand-lg py-6 py-lg-0">
        <div className='container header-container px-2' >
          <a href="/" className="logo d-flex align-items-center text-decoration-none p-3"style={logoStyle}>
            <img
              alt="Wheel of Dhamma"
              height="50"
              src={logoSrc} 
            />
          </a>

          <button
            className="navbar-toggler border-0 scale-down"
            type="button"
            onClick={handleToggle}
            aria-controls="top-navbar"
          >
            <span className={`navbar-toggler-icon ${togglerIconClass}`}></span>
          </button>
          <div
            className={`offcanvas offcanvas-end ${isOffcanvasOpen ? 'show' : ''}`}
            tabIndex="-1"
            id="top-navbar"
            aria-labelledby="top-navbarLabel"
          >
            <button
              className="navbar-toggler border-0"
              type="button"
              onClick={closeOffcanvas}
              aria-controls="top-navbar"
            >
              <div className="d-flex justify-content-end p-2">
                <span aria-hidden="true" className='closeIcon'>&times;</span>
              </div>
            </button>
            <ul className="navbar-nav ms-lg-auto p-4 p-lg-0"style={navbarStyle}>
              <li className="nav-item  py-2 dropdown mx-10">
                <a
                  className={`nav-link ${isMobile && 'dropdown-toggle'}`}
                  href="#pages"
                  role="button"
                  onmouseOver={() => handleDropdownClick('pages')}
                >
                  <span className={`body1 ${textColorClass}`}>Vipassana</span>
                  {!isMobile && <span className="addIcon">+</span>}
                </a>
                <ul className={`dropdown-menu ${activeDropdown === 'pages' ? 'show' : ''}`}>
                  <li className="item"><a className='item-link text body1' href="/what-is-vipassana">What is Vipassana</a></li>
                  <li className="item"><a className='item-link text body1' href="/art-of-living">The Art of living</a></li>
                  <li className="item"><a className='item-link text body1' href="/goenka-page">Mr. S.N.Goenka</a></li>
                  <li className="item"><a className='item-link text body1' href="/the-code-of-discipline">The Code of Discipline</a></li>
                  <li className="item"><a className='item-link text body1' href="/questions-answers">Question & Answers</a></li>
                  <li className="item"><a className='item-link text body1' href="/introductory-videos">Introductory Videos</a></li>
                  <li className="item"><a className='item-link text body1' href="/related-websites">Related Websites</a></li>
                </ul>
              </li>
              <li className="nav-item  py-2 dropdown mx-10">
                <a
                  className={`nav-link ${isMobile && 'dropdown-toggle'}`}
                  href="#blog"
                  role="button"
                  onmouseOver={() => handleDropdownClick('blog')}
                >
                  <span className={`body1 ${textColorClass}`}>Courses</span>
                  {!isMobile && <span className="addIcon">+</span>}
                </a>
                <ul className={`dropdown-menu ${activeDropdown === 'blog' ? 'show' : ''}`}>
                  <li className="item"><a className='item-link text body1' href="/how-to-apply">How to Apply</a></li>
                  <li className="item"><a className='item-link text body1' href="/type-of-courses">Type of Courses</a></li>
                  <li className="item"><a className='item-link text body1' href="/arrival-and-departure">Arrival & Departure</a></li>
                  <li className="item"><a className='item-link text body1' href="/during-the-course">During the Course</a></li>
                  <li className="item"><a className='item-link text body1' href="/what-to-bring">What to Bring</a></li>
                  <li className="item"><a className='item-link text body1' href="/time-table">Time Table</a></li>
                </ul>
              </li>
              <li className="nav-item  py-2 dropdown mx-10">
                <a className="nav-link" href="https://schedule.vridhamma.org/courses/pattana" target="_blank" rel="noreferrer">
                  <span className={`body1 ${textColorClass}`}>Course Schedule</span>
                </a>
              </li>
              <li className="nav-item  py-2 dropdown mx-10">
                <a
                  className={`nav-link ${isMobile && 'dropdown-toggle'}`}
                  href="#portfolio"
                  role="button"
                  onmouseOver={() => handleDropdownClick('portfolio')}
                >
                  <span className={`body1 ${textColorClass}`}>The Center</span>
                  {!isMobile && <span className="addIcon">+</span>}
                </a>
                <ul className={`dropdown-menu ${activeDropdown === 'portfolio' ? 'show' : ''}`}>
                  <li className="item"><a className='item-link text body1' href="/about-us">About us</a></li>
                  <li className="item"><a className='item-link text body1' href="/contact-us">Contact Us</a></li>
                </ul>
              </li>
              {!isAuthenticated && (<li className="nav-item  py-2 dropdown mx-10">
                <a className="nav-link" href="/login">
                  <span className={`body1 ${textColorClass}`}>O.S. login</span>
                </a>
              </li>
              )}
              {isAuthenticated && (
                <>
                  <li className="nav-item  py-2 dropdown mx-10">
                    <a className="nav-link" href="/services-login">
                      <span className={`body1 ${textColorClass}`}>Old Students</span>
                    </a>
                  </li>
                  <li className="nav-item py-2 mx-10">
                    <a className="nav-link" href="#" onClick={handleLogout}>
                      <span className={`body1 ${textColorClass}`}>Logout</span>
                    </a>
                  </li>
                </>


              )}
            </ul>

          </div>
        </div>
      </nav>
    </div>
  );
}
export default Header;

