import React, { useState, forwardRef } from 'react';
import './AboutUs.css'
// Import in your main file (e.g., index.js)
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Center9 from '../../TheCentre/assets/center9.JPG'
import Center10 from '../../TheCentre/assets/center10.JPG'
import Center11 from '../../TheCentre/assets/center11.JPG'
import Center12 from '../../TheCentre/assets/center12.jpg'

const Pagoda = forwardRef((props, ref,mobref) => {

    const [selectedImage, setSelectedImage] = useState('');
    const [selectedCaption, setSelectedCaption] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentSource, setCurrentSource] = useState(''); // This will track if we are in overview or gallery

    const PagodaImages = [
        Center10, // Replace with actual image imports or paths
        Center11,
        Center9,
        Center12,
        Center10, // Replace with actual image imports or paths
        Center11,

    ];

    const pagodaCaptions = [
        " Beautiful silhouette of pagoda after sunset.",
        "Stairs to cells.",
        "Inside of meditation cells.",
        "Panoramic view of circular pagoda inside.",
        " Beautiful silhouette of pagoda after sunset.",
        "Stairs to cells."
    ];
    const openModal = (index, source) => {
        if (source === 'pagoda') {
            setSelectedImage(PagodaImages[index]);
            setSelectedCaption(pagodaCaptions[index]);
            setCurrentSource('pagoda'); // Set the current source to gallery
        }
    }
    const handlePrevImage = () => {
        if (currentSource === 'pagoda') {
            const currentIndex = PagodaImages.indexOf(selectedImage);
            const prevIndex = (currentIndex - 1 + PagodaImages.length) % PagodaImages.length;
            setSelectedImage(PagodaImages[prevIndex]);
            setSelectedCaption(pagodaCaptions[prevIndex]);
        }
    }
    const handleNextImage = () => {
        if (currentSource === 'pagoda') {
            const currentIndex = PagodaImages.indexOf(selectedImage);
            const nextIndex = (currentIndex + 1) % PagodaImages.length;
            setSelectedImage(PagodaImages[nextIndex]);
            setSelectedCaption(pagodaCaptions[nextIndex]);
        }
    }
  

    return (
        <div ref={ref}>
            <section className="section  desktop-gallery">
                <h2 className="section-title">Pagoda</h2>
                <p className='body1 all-txts'>
                    At the end of the walkway on the other side of the Dhamma hall is the Pagoda also called
                    Shunyagar. The circular structure has the Principal teacher cell in the center. Students
                    get an opportunity to practice the task for the day for some time during the day as mentioned
                    in the schedule in individual cells allotted to them without any disturbance. This comes from
                    the concept of individual cells for monks in viharas at the time of Buddha. Female entry to
                    Pagoda is from the walkway of their residences whereas Male students use the entry from outside
                    of the Dhamma hall.
                </p>
                <div id="carouselPagoda" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselPagoda" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" data-bs-interval="1500"></button>
                        <button type="button" data-bs-target="#carouselPagoda" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center10}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 1"
                                        loading="lazy"
                                        onClick={() => openModal(0, 'pagoda')}
                                    />
                                    <p className="image-caption-gallery">Beautiful silhouette of pagoda after sunset.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center11}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 2"
                                        loading="lazy"
                                        onClick={() => openModal(1, 'pagoda')}
                                    />
                                    <p className="image-caption-gallery">Stairs to cells</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center9}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 3"
                                        loading="lazy"
                                        onClick={() => openModal(2, 'pagoda')}
                                    />
                                    <p className="image-caption-gallery">Inside of meditation cells.</p>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center12}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 4"
                                        loading="lazy"
                                        onClick={() => openModal(3, 'pagoda')}
                                    />
                                    <p className="image-caption-gallery">Panoramic view of circular pagoda inside.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center10}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 1"
                                        loading="lazy"
                                        onClick={() => openModal(0, 'pagoda')}
                                    />
                                    <p className="image-caption-gallery">Beautiful silhouette of pagoda after sunset.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center11}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 2"
                                        loading="lazy"
                                        onClick={() => openModal(1, 'pagoda')}
                                    />
                                    <p className="image-caption-gallery">Stairs to cells</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselPagoda" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselPagoda" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                {isModalOpen && (
                    <div className="modal-gall">
                        <span className="cross-btn" onClick={() => setIsModalOpen(false)}>&times;</span>
                        <img src={selectedImage} className="selected-gall" alt="Selected" />
                        <p className="image-caption">{selectedCaption}</p>
                        <button className="prev-btn" onClick={handlePrevImage}>‹</button>
                        <button className="next-btn" onClick={handleNextImage}>›</button>
                    </div>
                )}
            </section>
            {/*mobile view */}
            <section className="section mobile-gallery d-lg-none" >
                <h2 className="section-title">Pagoda</h2>
                <p className='body1 all-txts'>
                    At the end of the walkway on the other side of the Dhamma hall is the Pagoda also called
                    Shunyagar. The circular structure has the Principal teacher cell in the center. Students
                    get an opportunity to practice the task for the day for some time during the day as mentioned
                    in the schedule in individual cells allotted to them without any disturbance. This comes from
                    the concept of individual cells for monks in viharas at the time of Buddha. Female entry to
                    Pagoda is from the walkway of their residences whereas Male students use the entry from outside
                    of the Dhamma hall.
                </p>
                <div id="mobilecarouselPagoda" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                    <div class="carousel-indicators-mobile ">
                        <button type="button" data-bs-target="#mobilecarouselPagoda" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#mobilecarouselPagoda" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#mobilecarouselPagoda" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#mobilecarouselPagoda" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        <button type="button" data-bs-target="#mobilecarouselPagoda" data-bs-slide-to="4" aria-label="Slide 5"></button>
                        <button type="button" data-bs-target="#mobilecarouselPagoda" data-bs-slide-to="5" aria-label="Slide 6"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img
                                src={Center10}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 1"
                                loading="lazy"
                                onClick={() => openModal(0, 'pagoda')}
                            />
                            <p className="image-caption-gallery">Beautiful silhouette of pagoda after sunset.</p>
                        </div>
                        <div className="carousel-item active">
                            <img
                                src={Center11}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 2"
                                loading="lazy"
                                onClick={() => openModal(1, 'pagoda')}
                            />
                            <p className="image-caption-gallery">Stairs to cells</p>
                        </div>
                        <div className="carousel-item active">
                            <img
                                src={Center9}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 3"
                                loading="lazy"
                                onClick={() => openModal(2, 'pagoda')}
                            />
                            <p className="image-caption-gallery">Inside of meditation cells.</p>
                        </div>
                        <div className="carousel-item active">
                            <img
                                src={Center12}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 4"
                                loading="lazy"
                                onClick={() => openModal(3, 'pagoda')}
                            />
                            <p className="image-caption-gallery">Panoramic view of circular pagoda inside.</p>
                        </div>
                        <div className="carousel-item active">
                            <img
                                src={Center10}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 1"
                                loading="lazy"
                                onClick={() => openModal(0, 'pagoda')}
                            />
                            <p className="image-caption-gallery">Beautiful silhouette of pagoda after sunset.</p>
                        </div>
                        <div className="carousel-item active">
                            <img
                                src={Center11}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 2"
                                loading="lazy"
                                onClick={() => openModal(1, 'pagoda')}
                            />
                            <p className="image-caption-gallery">Stairs to cells</p>
                        </div>
                    </div>

                    <button className="carousel-control-prev" type="button" data-bs-target="#mobilecarouselPagoda" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#mobilecarouselPagoda" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                {isModalOpen && (
                    <div className="modal-gall">
                        <span className="cross-btn" onClick={() => setIsModalOpen(false)}>&times;</span>
                        <img src={selectedImage} className="selected-gall" alt="Selected" />
                        <p className="image-caption">{selectedCaption}</p>
                        <button className="prev-btn" onClick={handlePrevImage}>‹</button>
                        <button className="next-btn" onClick={handleNextImage}>›</button>
                    </div>
                )}

            </section>

        </div>
    )
})

export default Pagoda;