import React, { useState, useEffect, useRef } from 'react';
import './AboutUs.css';
import Welcome from './Welcome';
import Overview from './Overview';
import PhotoGallery from './PhotoGallery';
import MeditationHall from './MeditationHall';
import Pagoda from './Pagoda';
import Residence from './Residence';
import Dining from './Dining';
import Maps from './Maps';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const AboutUs = () => {
    const [isFixed, setIsFixed] = useState(false);
    const overview = useRef(null);
    const photoGallery = useRef(null);
    const meditationHall = useRef(null);
    const pagoda = useRef(null);
    const residences = useRef(null);
    const diningRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => setIsFixed(window.scrollY > 400);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="container custom-container the-centre-cont mb-4 mt-5 d-flex">
            <div className="center-cont mt-5">
                <Welcome />
                <Overview ref={overview} />
                <PhotoGallery ref={photoGallery} />
                <MeditationHall ref={meditationHall} />
                <Pagoda ref={pagoda} />
                <Residence ref={residences} />
                <Dining ref={diningRef} />
                <Maps />
            </div>
            <div className={`scroll-cont scroll-desktop ${isFixed ? 'fixed' : ''}`}>
                <div className="scroll-cont-in">
                    <h6>Dhamma Pattana</h6>
                    <div className="all-linked-txt">
                        <p className="center-txt" onClick={() => {
                            const offset = 80; // Height of your sticky navigation or header
                            const top = overview.current.offsetTop - offset;
                            window.scrollTo({ top, behavior: 'smooth' });
                        }}>Overview</p>
                        <p className="center-txt" onClick={() => {
                            const offset = 100; // Height of your sticky navigation or header
                            const top = photoGallery.current.offsetTop - offset;
                            window.scrollTo({ top, behavior: 'smooth' });
                        }}>Photo Gallery</p>
                        <p className="center-txt" onClick={() => {
                            const offset = 100; // Height of your sticky navigation or header
                            const top = meditationHall.current.offsetTop - offset;
                            window.scrollTo({ top, behavior: 'smooth' });
                        }}>Meditation Hall</p>
                        <p className="center-txt" onClick={() => {
                            const offset = 100; // Height of your sticky navigation or header
                            const top = pagoda.current.offsetTop - offset;
                            window.scrollTo({ top, behavior: 'smooth' });
                        }}>Pagoda</p>
                        <p className="center-txt" onClick={() => {
                            const offset = 100; // Height of your sticky navigation or header
                            const top = residences.current.offsetTop - offset;
                            window.scrollTo({ top, behavior: 'smooth' });
                        }}>Residences</p>
                        <p className="center-txt" onClick={() => {
                            const offset = 100; // Height of your sticky navigation or header
                            const top = diningRef.current.offsetTop - offset;
                            window.scrollTo({ top, behavior: 'smooth' });
                        }}>Dining</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
