import React, { useState, forwardRef } from 'react';
import './AboutUs.css'
// Import in your main file (e.g., index.js)
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Gallery1 from '../../TheCentre/assets/3.jpg'
import Gallery2 from '../../TheCentre/assets/6.jpg'
import Gallery3 from '../../TheCentre/assets/7.jpg'
import Gallery4 from '../../TheCentre/assets/8.jpg'
import Gallery5 from '../../TheCentre/assets/center17.jpg'
import Gallery6 from '../../TheCentre/assets/img2.jpg'
import Gallery8 from '../../TheCentre/assets/img4.jpg'
import Gallery9 from '../../TheCentre/assets/img5.jpg'
import Gallery10 from '../../TheCentre/assets/img8.jpg'

const PhotoGallery = forwardRef((props, ref,mobref) => {

    const [selectedImage, setSelectedImage] = useState('');
    const [selectedCaption, setSelectedCaption] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentSource, setCurrentSource] = useState(''); // This will track if we are in overview or gallery

    const galleryImages = [
        Gallery1, // Replace with actual image imports or paths
        Gallery2,
        Gallery3,
        Gallery4,
        Gallery5,
        Gallery6,
        Gallery8,
        Gallery9,
        Gallery10,
    ];

    const galleryCaptions = [
        "View of garden and Mumbai from terrace.",
        "Colourful flower on pathway.",
        "Registration area male side.",
        "Colourful leaves.",
        "View of dining hall male side.",
        "View of the centre from female walking area.",
        "Panoramic view of Dhamma hall.",
        "Residence with view of Pagoda from the window.",
        "View from global Pagoda.",
    ];
    const openModal = (index, source) => {
        if (source === 'gallery') {
            setSelectedImage(galleryImages[index]);
            setSelectedCaption(galleryCaptions[index]);
            setCurrentSource('gallery'); // Set the current source to gallery
        }
    }
    const handlePrevImage = () => {
        if (currentSource === 'gallery') {
            const currentIndex = galleryImages.indexOf(selectedImage);
            const prevIndex = (currentIndex - 1 + galleryImages.length) % galleryImages.length;
            setSelectedImage(galleryImages[prevIndex]);
            setSelectedCaption(galleryCaptions[prevIndex]);
        }
    }
    const handleNextImage = () => {
        if (currentSource === 'gallery') {
            const currentIndex = galleryImages.indexOf(selectedImage);
            const nextIndex = (currentIndex + 1) % galleryImages.length;
            setSelectedImage(galleryImages[nextIndex]);
            setSelectedCaption(galleryCaptions[nextIndex]);
        }
    }
    

    return (
        <div ref={ref}>
            <section className="section desktop-gallery " >
                <h2 className="section-title">Photo Gallery</h2>
                <div id="carouselPhotoGallery" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselPhotoGallery" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselPhotoGallery" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselPhotoGallery" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>

                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Gallery1}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 1"
                                        onClick={() => openModal(0, 'gallery')}
                                    />
                                    <p className="image-caption-gallery">View of garden and Mumbai from terrace.</p>

                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Gallery2}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 2"
                                        onClick={() => openModal(1, 'gallery')}
                                    />
                                    <p className="image-caption-gallery">Colourful flower on pathway.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Gallery3}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 3"
                                        onClick={() => openModal(2, 'gallery')}
                                    />
                                    <p className="image-caption-gallery">Registration area male side.</p>
                                </div>
                            </div>
                        </div>

                        <div className="carousel-item">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Gallery4}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 4"
                                        onClick={() => openModal(3, 'gallery')}
                                    />
                                    <p className="image-caption-gallery">Colourful leaves.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Gallery5}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 5"
                                        onClick={() => openModal(4, 'gallery')}
                                    />
                                    <p className="image-caption-gallery">View of dining hall male side.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Gallery6}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 6"
                                        onClick={() => openModal(5, 'gallery')}
                                    />
                                    <p className="image-caption-gallery">View of the centre from female walking area.</p>
                                </div>
                            </div>
                        </div>

                        <div className="carousel-item">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Gallery8}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 8"
                                        onClick={() => openModal(6, 'gallery')}
                                    />
                                    <p className="image-caption-gallery">Paranomic view of Dhamma hall.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Gallery9}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 9"
                                        onClick={() => openModal(7, 'gallery')}
                                    />
                                    <p className="image-caption-gallery">Residence with view of Pagoda from the window.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Gallery10}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 10"
                                        onClick={() => openModal(8, 'gallery')}
                                    />
                                    <p className="image-caption-gallery">View from global Pagoda.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselPhotoGallery" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselPhotoGallery" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>

                {isModalOpen && (
                    <div className="modal-gall">
                        <span className="cross-btn" onClick={() => setIsModalOpen(false)}>&times;</span>
                        <img src={selectedImage} className="selected-gall" alt="Selected" />
                        <p className="image-caption">{selectedCaption}</p>
                        <button className="prev-btn" onClick={handlePrevImage}>‹</button>
                        <button className="next-btn" onClick={handleNextImage}>›</button>
                    </div>
                )}

            </section>

            {/* Mobile Version: Visible only on small screens */}
            <section className="section mobile-gallery d-lg-none">
                <h2 className="section-title">Photo Gallery</h2>
                <div id="mobileCarouselPhotoGallery" className="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-indicators-mobile ">
                        <button type="button" data-bs-target="#mobileCarouselPhotoGallery" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#mobileCarouselPhotoGallery" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#mobileCarouselPhotoGallery" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#mobileCarouselPhotoGallery" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        <button type="button" data-bs-target="#mobileCarouselPhotoGallery" data-bs-slide-to="4" aria-label="Slide 5"></button>
                        <button type="button" data-bs-target="#mobileCarouselPhotoGallery" data-bs-slide-to="5" aria-label="Slide 6"></button>
                        <button type="button" data-bs-target="#mobileCarouselPhotoGallery" data-bs-slide-to="6" aria-label="Slide 7"></button>
                        <button type="button" data-bs-target="#mobileCarouselPhotoGallery" data-bs-slide-to="7" aria-label="Slide 8"></button>
                        <button type="button" data-bs-target="#mobileCarouselPhotoGallery" data-bs-slide-to="8" aria-label="Slide 9"></button>
                    </div>

                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img
                                src={Gallery1}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 1"
                                onClick={() => openModal(0, 'gallery')}
                            />
                            <p className="image-caption-gallery">View of garden and Mumbai from terrace.</p>
                        </div>
                        <div className="carousel-item">
                            <img
                                src={Gallery2}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 2"
                                onClick={() => openModal(1, 'gallery')}
                            />
                            <p className="image-caption-gallery">Colourful flower on pathway.</p>
                        </div>
                        <div className="carousel-item">
                            <img
                                src={Gallery3}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 3"
                                onClick={() => openModal(2, 'gallery')}
                            />
                            <p className="image-caption-gallery">Registration area male side.</p>
                        </div>
                        <div className="carousel-item">
                            <img
                                src={Gallery4}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 4"
                                onClick={() => openModal(3, 'gallery')}
                            />
                            <p className="image-caption-gallery">Colourful leaves.</p>
                        </div>
                        <div className="carousel-item">
                            <img
                                src={Gallery5}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 4"
                                onClick={() => openModal(4, 'gallery')}
                            />
                            <p className="image-caption-gallery">View of dining hall male side.</p>
                        </div><div className="carousel-item">
                            <img
                                src={Gallery6}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 4"
                                onClick={() => openModal(5, 'gallery')}
                            />
                            <p className="image-caption-gallery">View of the centre from female walking area.</p>
                        </div><div className="carousel-item">
                            <img
                                src={Gallery8}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 4"
                                onClick={() => openModal(6, 'gallery')}
                            />
                            <p className="image-caption-gallery">Paranomic view of Dhamma hall.</p>
                        </div>
                        <div className="carousel-item">
                            <img
                                src={Gallery9}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 4"
                                onClick={() => openModal(7, 'gallery')}
                            />
                            <p className="image-caption-gallery">Residence with view of Pagoda from the window.</p>
                        </div>
                        <div className="carousel-item">
                            <img
                                src={Gallery10}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 4"
                                onClick={() => openModal(8, 'gallery')}
                            />
                            <p className="image-caption-gallery">View from global Pagoda.</p>
                        </div>
                    </div>

                    <button class="carousel-control-prev" type="button" data-bs-target="#mobileCarouselPhotoGallery" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#mobileCarouselPhotoGallery" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
                {isModalOpen && (
                    <div className="modal-gall">
                        <span className="cross-btn" onClick={() => setIsModalOpen(false)}>&times;</span>
                        <img src={selectedImage} className="selected-gall" alt="Selected" />
                        <p className="image-caption">{selectedCaption}</p>
                        <button className="prev-btn" onClick={handlePrevImage}>‹</button>
                        <button className="next-btn" onClick={handleNextImage}>›</button>
                    </div>
                )}

            </section>
        </div>
    )
})

export default PhotoGallery;