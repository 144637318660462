import React, { useState, forwardRef } from 'react';
import './AboutUs.css'
// Import in your main file (e.g., index.js)
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Center13 from '../../TheCentre/assets/center13.JPG'
import Center14 from './../../TheCentre/assets/center14.JPG'
import Center15 from '../../TheCentre/assets/center15.JPG'
import Center16 from '../../TheCentre/assets/center16.JPG'

const Residence = forwardRef((props, ref,mobref) => {

    const [selectedImage, setSelectedImage] = useState('');
    const [selectedCaption, setSelectedCaption] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentSource, setCurrentSource] = useState(''); // This will track if we are in overview or gallery

    const ResidenceImages = [
        Center13, // Replace with actual image imports or paths
        Center14,
        Center15,
        Center16,
        Center13, // Replace with actual image imports or paths
        Center14,

    ];

    const ResidenceCaptions = [
        " Female rooms.",
        "Male rooms.",
        "Bathrooom.",
        "View of passage area with lift.",
        " Female rooms.",
        "Male rooms.",
    ];
    const openModal = (index, source) => {
        if (source === 'residence') {
            setSelectedImage(ResidenceImages[index]);
            setSelectedCaption(ResidenceCaptions[index]);
            setCurrentSource('residence'); // Set the current source to gallery
        }
    }
    const handlePrevImage = () => {
        if (currentSource === 'residence') {
            const currentIndex = ResidenceImages.indexOf(selectedImage);
            const prevIndex = (currentIndex - 1 + ResidenceImages.length) % ResidenceImages.length;
            setSelectedImage(ResidenceImages[prevIndex]);
            setSelectedCaption(ResidenceCaptions[prevIndex]);
        }
    }
    const handleNextImage = () => {
        if (currentSource === 'residence') {
            const currentIndex = ResidenceImages.indexOf(selectedImage);
            const nextIndex = (currentIndex + 1) % ResidenceImages.length;
            setSelectedImage(ResidenceImages[nextIndex]);
            setSelectedCaption(ResidenceCaptions[nextIndex]);
        }
    }
    

    return (
        <div ref={ref}>
            <section className="section  desktop-gallery" >
                <h2 className="section-title"> Residences</h2>
                <p className='body1 all-txts'>
                    Male and Female residences are segregated. Each room has an attached bathroom with hot
                    water facilities. Rooms being air-conditioned, one is advised to bring appropriate woolens.
                    The center is a compact two-storey building and one is required to climb two stairs, 3 to 4
                    times a day. However there is an elevator available for use by the elderly and needy.
                    Accommodation is usually individual, however in certain unavoidable circumstances one may have
                    to share a room with another participant.
                </p>
                <div id="carouselResidences" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselResidences" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" data-bs-interval="1500"></button>
                        <button type="button" data-bs-target="#carouselResidences" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center13}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 1"
                                        loading="lazy"
                                        onClick={() => openModal(0, 'residence')}
                                    />
                                    <p className="image-caption-gallery">Female rooms.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center14}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 2"
                                        loading="lazy"
                                        onClick={() => openModal(1, 'residence')}
                                    />
                                    <p className="image-caption-gallery">Male rooms</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center15}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 3"
                                        loading="lazy"
                                        onClick={() => openModal(2, 'residence')}
                                    />
                                    <p className="image-caption-gallery">Bathroom.</p>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center16}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 4"
                                        loading="lazy"
                                        onClick={() => openModal(3, 'residence')}
                                    />
                                    <p className="image-caption-gallery">View of passage area with lift.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center13}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 1"
                                        loading="lazy"
                                        onClick={() => openModal(0, 'residence')}
                                    />
                                    <p className="image-caption-gallery">Female rooms.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center14}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 2"
                                        loading="lazy"
                                        onClick={() => openModal(1, 'residence')}
                                    />
                                    <p className="image-caption-gallery">Male rooms.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselResidences" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselResidences" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                {isModalOpen && (
                    <div className="modal-gall">
                        <span className="cross-btn" onClick={() => setIsModalOpen(false)}>&times;</span>
                        <img src={selectedImage} className="selected-gall" alt="Selected" />
                        <p className="image-caption">{selectedCaption}</p>
                        <button className="prev-btn" onClick={handlePrevImage}>‹</button>
                        <button className="next-btn" onClick={handleNextImage}>›</button>
                    </div>
                )}
            </section>
            {/*mobile view */}
            <section className="section mobile-gallery d-lg-none" >
                <h2 className="section-title"> Residences</h2>
                <p className='body1 all-txts'>
                    Male and Female residences are segregated. Each room has an attached bathroom with hot
                    water facilities. Rooms being air-conditioned, one is advised to bring appropriate woolens.
                    The center is a compact two-storey building and one is required to climb two stairs, 3 to 4
                    times a day. However there is an elevator available for use by the elderly and needy.
                    Accommodation is usually individual, however in certain unavoidable circumstances one may have
                    to share a room with another participant.
                </p>
                <div id="mobilecarouselResidences" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                    <div class="carousel-indicators-mobile ">
                        <button type="button" data-bs-target="#mobilecarouselResidences" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#mobilecarouselResidences" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#mobilecarouselResidences" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#mobilecarouselResidences" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        <button type="button" data-bs-target="#mobilecarouselResidences" data-bs-slide-to="4" aria-label="Slide 5"></button>
                        <button type="button" data-bs-target="#mobilecarouselResidences" data-bs-slide-to="5" aria-label="Slide 6"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img
                                src={Center13}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 1"
                                loading="lazy"
                                onClick={() => openModal(0, 'residence')}
                            />
                            <p className="image-caption-gallery">Female rooms.</p>
                        </div>
                        <div className="carousel-item active">
                            <img
                                src={Center14}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 2"
                                loading="lazy"
                                onClick={() => openModal(1, 'residence')}
                            />
                            <p className="image-caption-gallery">Male rooms</p>
                        </div>
                        <div className="carousel-item active">
                            <img
                                src={Center15}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 3"
                                loading="lazy"
                                onClick={() => openModal(2, 'residence')}
                            />
                            <p className="image-caption-gallery">Bathroom.</p>
                        </div>
                        <div className="carousel-item active">
                            <img
                                src={Center16}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 4"
                                loading="lazy"
                                onClick={() => openModal(3, 'residence')}
                            />
                            <p className="image-caption-gallery">View of passage area with lift.</p>
                        </div>
                        <div className="carousel-item active">
                            <img
                                src={Center13}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 1"
                                loading="lazy"
                                onClick={() => openModal(0, 'residence')}
                            />
                            <p className="image-caption-gallery">Female rooms.</p>
                        </div>
                        <div className="carousel-item active">
                            <img
                                src={Center14}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 2"
                                loading="lazy"
                                onClick={() => openModal(1, 'residence')}
                            />
                            <p className="image-caption-gallery">Male rooms.</p>
                        </div>
                    </div>

                    <button className="carousel-control-prev" type="button" data-bs-target="#mobilecarouselResidences" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#mobilecarouselResidences" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                {isModalOpen && (
                    <div className="modal-gall">
                        <span className="cross-btn" onClick={() => setIsModalOpen(false)}>&times;</span>
                        <img src={selectedImage} className="selected-gall" alt="Selected" />
                        <p className="image-caption">{selectedCaption}</p>
                        <button className="prev-btn" onClick={handlePrevImage}>‹</button>
                        <button className="next-btn" onClick={handleNextImage}>›</button>
                    </div>
                )}

            </section>


        </div>
    )
})

export default Residence;