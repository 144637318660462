import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PageNotFound.css';

function PageNotFound() {
  const navigate = useNavigate();

  const handleHomeRedirect = () => {
    navigate('/');
  };

  return (
    <div className="circle-wrapper">
      <div className="not-found-container">
        <div className="text-wrapper">
          <h1 className="error-title animated-text">404</h1>
          <h2 className="error-text  animated-text">Oops! Page Not Found</h2>
          <p className="subtext  animated-text">
            Sorry, the page you're looking for doesn't exist.
          </p>
        </div>
        <button className="home-button animated-button" onClick={handleHomeRedirect}>
          Go Back Home
        </button>
      </div>
    </div>
  );
}

export default PageNotFound;
