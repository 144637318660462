import React, { useState, forwardRef } from 'react';
import './AboutUs.css'
// Import in your main file (e.g., index.js)
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Center2 from '../../TheCentre/assets/center2.JPG'
import Center3 from '../../TheCentre/assets/center3.jpg'
import Center4 from '../../TheCentre/assets/center4.JPG'
import Center5 from '../../TheCentre/assets/center5.JPG'
import Center6 from '../../TheCentre/assets/center6.JPG'
import Center7 from '../../TheCentre/assets/center7.JPG'
const Overview = forwardRef((props, ref,mobref) => {

    const [selectedImage, setSelectedImage] = useState('');
    const [selectedCaption, setSelectedCaption] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentSource, setCurrentSource] = useState(''); // This will track if we are in overview or gallery

    const overviewImages = [
        Center2, // Replace with actual image imports or paths
        Center3,
        Center4,
        Center5,
        Center6,
        Center7,
    ];
    const overviewCaptions = [
        "View of center from pagoda walking turf.",
        "View of center building from female walking area.",
        "Male walking area.",
        "Colorful plants beside walking path.",
        "Walking landscape in background with greenery.",
        "Male side walking path adjacent to building.",
    ];
    const openModal = (index, source) => {
        if (source === 'overview') {
            setSelectedImage(overviewImages[index]);
            setSelectedCaption(overviewCaptions[index]);
            setCurrentSource('overview'); // Set the current source to overview
        }
    }
    const handlePrevImage = () => {
        if (currentSource === 'overview') {
            const currentIndex = overviewImages.indexOf(selectedImage);
            const prevIndex = (currentIndex - 1 + overviewImages.length) % overviewImages.length;
            setSelectedImage(overviewImages[prevIndex]);
            setSelectedCaption(overviewCaptions[prevIndex]);
        }
    }
    const handleNextImage = () => {
        if (currentSource === 'overview') {
            const currentIndex = overviewImages.indexOf(selectedImage);
            const nextIndex = (currentIndex + 1) % overviewImages.length;
            setSelectedImage(overviewImages[nextIndex]);
            setSelectedCaption(overviewCaptions[nextIndex]);
        }
    }
   

    return (
        <div ref={ref}>
            <section className="section  desktop-gallery" >
                <h2 className="section-title">About us</h2>
                <h2 className="section-title">Overview</h2>
                <p className='body1 all-txts'>
                    DhammaPattana is a three storeyed building (Ground + 3) spread over ___ acres of land comprising
                    of air-conditioned Dhamma Hall and Pagoda respectively for Group and individual Meditation for 100+
                    students, Segregated Male and Female air-conditioned residences and a dining hall. Also has a landscaped
                    garden for walking.
                </p>
                <div id="carouselExampleIndicatorsAboutUs" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicatorsAboutUs" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" data-bs-interval="1500"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicatorsAboutUs" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center2}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 1"
                                        loading="lazy"
                                        onClick={() => openModal(0, 'overview')}
                                    />
                                    <p className="image-caption-gallery">View of center from pagoda walking turf.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center3}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 2"
                                        loading="lazy"
                                        onClick={() => openModal(1, 'overview')}
                                    />
                                    <p className="image-caption-gallery">View of center building from female walking area.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center4}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 3"
                                        loading="lazy"
                                        onClick={() => openModal(2, 'overview')}
                                    />
                                    <p className="image-caption-gallery">Male walking area.</p>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center5}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 4"
                                        loading="lazy"
                                        onClick={() => openModal(3, 'overview')}
                                    />
                                    <p className="image-caption-gallery">Colorful plants beside walking path.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center6}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 5"
                                        loading="lazy"
                                        onClick={() => openModal(4, 'overview')}
                                    />
                                    <p className="image-caption-gallery">Walking landscape in background with greenery. </p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center7}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 6"
                                        loading="lazy"
                                        onClick={() => openModal(5, 'overview')}
                                    />
                                    <p className="image-caption-gallery">Male side walking path adjacent to building.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicatorsAboutUs" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicatorsAboutUs" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                {isModalOpen && (
                    <div className="modal-gall">
                        <span className="cross-btn" onClick={() => setIsModalOpen(false)}>&times;</span>
                        <img src={selectedImage} className="selected-gall" alt="Selected" />
                        <p className="image-caption">{selectedCaption}</p>
                        <button className="prev-btn" onClick={handlePrevImage}>‹</button>
                        <button className="next-btn" onClick={handleNextImage}>›</button>
                    </div>
                )}


            </section>
            {/*mobile view */}
            <section className="section mobile-gallery d-lg-none" >
                <h2 className="section-title">About us</h2>
                <h2 className="section-title">Overview</h2>
                <p className='body1 all-txts'>
                    DhammaPattana is a three storeyed building (Ground + 3) spread over ___ acres of land comprising
                    of air-conditioned Dhamma Hall and Pagoda respectively for Group and individual Meditation for 100+
                    students, Segregated Male and Female air-conditioned residences and a dining hall. Also has a landscaped
                    garden for walking.
                </p>
                <div id="mobilecarouselExampleIndicatorsAboutUs" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                    <div class="carousel-indicators-mobile ">
                        <button type="button" data-bs-target="#mobileCarouselExampleIndicatorsAboutUs" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#mobileCarouselExampleIndicatorsAboutUs" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#mobileCarouselExampleIndicatorsAboutUs" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#mobileCarouselExampleIndicatorsAboutUs" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        <button type="button" data-bs-target="#mobileCarouselExampleIndicatorsAboutUs" data-bs-slide-to="4" aria-label="Slide 5"></button>
                        <button type="button" data-bs-target="#mobileCarouselExampleIndicatorsAboutUs" data-bs-slide-to="5" aria-label="Slide 6"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img
                                src={Center2}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 1"
                                onClick={() => openModal(0, 'overview')}
                            />
                            <p className="image-caption-gallery">View of center from pagoda walking turf.</p>
                        </div>
                        <div className="carousel-item">
                            <img
                                src={Center3}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 2"
                                onClick={() => openModal(1, 'overview')}
                            />
                            <p className="image-caption-gallery">View of center building from female walking area.</p>
                        </div>
                        <div className="carousel-item">
                            <img
                                src={Center4}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 3"
                                onClick={() => openModal(2, 'overview')}
                            />
                            <p className="image-caption-gallery">Male walking area.</p>
                        </div>
                        <div className="carousel-item">
                            <img
                                src={Center6}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 4"
                                onClick={() => openModal(3, 'overview')}
                            />
                            <p className="image-caption-gallery">Colorful plants beside walking path..</p>
                        </div>
                        <div className="carousel-item">
                            <img
                                src={Center6}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 4"
                                onClick={() => openModal(4, 'overview')}
                            />
                            <p className="image-caption-gallery">Walking landscape in background with greenery. </p>
                        </div><div className="carousel-item">
                            <img
                                src={Center7}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 4"
                                onClick={() => openModal(5, 'overview')}
                            />
                            <p className="image-caption-gallery">Male side walking path adjacent to building.</p>
                        </div>
                    </div>

                    <button className="carousel-control-prev" type="button" data-bs-target="#mobilecarouselExampleIndicatorsAboutUs" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#mobilecarouselExampleIndicatorsAboutUs" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                {isModalOpen && (
                    <div className="modal-gall">
                        <span className="cross-btn" onClick={() => setIsModalOpen(false)}>&times;</span>
                        <img src={selectedImage} className="selected-gall" alt="Selected" />
                        <p className="image-caption">{selectedCaption}</p>
                        <button className="prev-btn" onClick={handlePrevImage}>‹</button>
                        <button className="next-btn" onClick={handleNextImage}>›</button>
                    </div>
                )}

            </section>
        </div>
    )
})

export default Overview;