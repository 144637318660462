import React, { useState, forwardRef } from 'react';
import './AboutUs.css'
// Import in your main file (e.g., index.js)
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Center8 from '../../TheCentre/assets/center8.JPG'
import Center9 from '../../TheCentre/assets/center9.JPG'

const MeditationHall = forwardRef((props, ref,mobref) => {

    const [selectedImage, setSelectedImage] = useState('');
    const [selectedCaption, setSelectedCaption] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentSource, setCurrentSource] = useState(''); // This will track if we are in overview or gallery

    const MeditationImages = [
        Center8, // Replace with actual image imports or paths
        Center9,
        Center8, // Replace with actual image imports or paths
        Center9,
        Center8, // Replace with actual image imports or paths
        Center9,

    ];

    const MeditationCaptions = [
        " Interior of Meditation hall.",
        "Hall view from male side entrance.",
        " Interior of Meditation hall.",
        "Hall view from male side entrance.",
        " Interior of Meditation hall.",
        "Hall view from male side entrance.",
    ];
    const openModal = (index, source) => {
        if (source === 'meditation') {
            setSelectedImage(MeditationImages[index]);
            setSelectedCaption(MeditationCaptions[index]);
            setCurrentSource('meditation'); // Set the current source to gallery
        }
    }
    const handlePrevImage = () => {
        if (currentSource === 'meditation') {
            const currentIndex = MeditationImages.indexOf(selectedImage);
            const prevIndex = (currentIndex - 1 + MeditationImages.length) % MeditationImages.length;
            setSelectedImage(MeditationImages[prevIndex]);
            setSelectedCaption(MeditationCaptions[prevIndex]);
        }
    }
    const handleNextImage = () => {
        if (currentSource === 'meditation') {
            const currentIndex = MeditationImages.indexOf(selectedImage);
            const nextIndex = (currentIndex + 1) % MeditationImages.length;
            setSelectedImage(MeditationImages[nextIndex]);
            setSelectedCaption(MeditationCaptions[nextIndex]);
        }
    }
   


    return (
        <div ref={ref}>
            <section className="section  desktop-gallery" >
                <h2 className="section-title">Meditation Hall</h2>
                <p className='body1 all-txts'>
                    Also called Dhamma Hall is on the 2nd Floor having Male and Female residences on either
                    side of the hallway. The teacher rooms and the Mini Dhamma hall are adjacent to the Meditation
                    hall. Mini Dhamma hall is used for interviewing students personally and also as other language halls if required.
                </p>
                <div id="carouselMeditationHall" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselMeditationHall" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" data-bs-interval="1500"></button>
                        <button type="button" data-bs-target="#carouselMeditationHall" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center8}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 1"
                                        loading="lazy"
                                        onClick={() => openModal(0, 'meditation')}
                                    />
                                    <p className="image-caption-gallery">Interior of Meditation hall.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center9}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 2"
                                        loading="lazy"
                                        onClick={() => openModal(1, 'meditation')}
                                    />
                                    <p className="image-caption-gallery">Hall view from male side entrance.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center8}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 1"
                                        loading="lazy"
                                        onClick={() => openModal(0, 'meditation')}
                                    />
                                    <p className="image-caption-gallery">Interior of Meditation hall.</p>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center9}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 2"
                                        loading="lazy"
                                        onClick={() => openModal(1, 'meditation')}
                                    />
                                    <p className="image-caption-gallery">Hall view from male side entrance.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center8}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 1"
                                        loading="lazy"
                                        onClick={() => openModal(0, 'meditation')}
                                    />
                                    <p className="image-caption-gallery">Interior of Meditation hall.</p>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 gallery-grid">
                                    <img
                                        src={Center9}
                                        className="d-block w-100 img-gallery"
                                        alt="Image 2"
                                        loading="lazy"
                                        onClick={() => openModal(1, 'meditation')}
                                    />
                                    <p className="image-caption-gallery">Hall view from male side entrance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselMeditationHall" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselMeditationHall" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                {isModalOpen && (
                    <div className="modal-gall">
                        <span className="cross-btn" onClick={() => setIsModalOpen(false)}>&times;</span>
                        <img src={selectedImage} className="selected-gall" alt="Selected" />
                        <p className="image-caption">{selectedCaption}</p>
                        <button className="prev-btn" onClick={handlePrevImage}>‹</button>
                        <button className="next-btn" onClick={handleNextImage}>›</button>
                    </div>
                )}

            </section>
            {/*mobile view */}
            <section className="section mobile-gallery d-lg-none" >
                <h2 className="section-title">Meditation Hall</h2>
                <p className='body1 all-txts'>
                    Also called Dhamma Hall is on the 2nd Floor having Male and Female residences on either
                    side of the hallway. The teacher rooms and the Mini Dhamma hall are adjacent to the Meditation
                    hall. Mini Dhamma hall is used for interviewing students personally and also as other language halls if required.
                </p>
                <div id="mobilecarouselMeditationHall" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                    <div class="carousel-indicators-mobile ">
                        <button type="button" data-bs-target="#mobilecarouselMeditationHall" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#mobilecarouselMeditationHall" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#mobilecarouselMeditationHall" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#mobilecarouselMeditationHall" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        <button type="button" data-bs-target="#mobilecarouselMeditationHall" data-bs-slide-to="4" aria-label="Slide 5"></button>
                        <button type="button" data-bs-target="#mobilecarouselMeditationHall" data-bs-slide-to="5" aria-label="Slide 6"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img
                                src={Center8}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 1"
                                loading="lazy"
                                onClick={() => openModal(0, 'meditation')}
                            />
                            <p className="image-caption-gallery">Interior of Meditation hall.</p>
                        </div>
                        <div className="carousel-item active">
                            <img
                                src={Center9}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 2"
                                loading="lazy"
                                onClick={() => openModal(1, 'meditation')}
                            />
                            <p className="image-caption-gallery">Hall view from male side entrance.</p>
                        </div>
                        <div className="carousel-item active">
                            <img
                                src={Center8}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 1"
                                loading="lazy"
                                onClick={() => openModal(0, 'meditation')}
                            />
                            <p className="image-caption-gallery">Interior of Meditation hall.</p>
                        </div>
                        <div className="carousel-item active">
                            <img
                                src={Center9}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 2"
                                loading="lazy"
                                onClick={() => openModal(1, 'meditation')}
                            />
                            <p className="image-caption-gallery">Hall view from male side entrance.</p>
                        </div>
                        <div className="carousel-item active">
                            <img
                                src={Center8}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 1"
                                loading="lazy"
                                onClick={() => openModal(0, 'meditation')}
                            />
                            <p className="image-caption-gallery">Interior of Meditation hall.</p>
                        </div>
                        <div className="carousel-item active">
                            <img
                                src={Center9}
                                className="d-block w-100 img-mobile-gallery"
                                alt="Image 2"
                                loading="lazy"
                                onClick={() => openModal(1, 'meditation')}
                            />
                            <p className="image-caption-gallery">Hall view from male side entrance.</p>
                        </div>
                    </div>

                    <button className="carousel-control-prev" type="button" data-bs-target="#mobilecarouselMeditationHall" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#mobilecarouselMeditationHall" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                {isModalOpen && (
                    <div className="modal-gall">
                        <span className="cross-btn" onClick={() => setIsModalOpen(false)}>&times;</span>
                        <img src={selectedImage} className="selected-gall" alt="Selected" />
                        <p className="image-caption">{selectedCaption}</p>
                        <button className="prev-btn" onClick={handlePrevImage}>‹</button>
                        <button className="next-btn" onClick={handleNextImage}>›</button>
                    </div>
                )}

            </section>

        </div>
    )
});

export default MeditationHall;